.blog {
    margin: auto;
    width: 90vw;
    height: 60vh;
    text-align: center;
    padding-bottom: 7em;
}

.blog h1 {
    color: #007b60;
    margin: 1em 0 0.2em 0;
    text-align: center;
}

.blog h2 {
    margin: 2em 0 0.2em 0;
}

#facebookPosts {
    color: #007b60;
    margin: 1em 0 0.2em 0;
    width: 340px;
    height: 500px;
}