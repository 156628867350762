.resources {
    margin: auto;
    width: 90vw;
    text-align: justify;
    padding-bottom: 60em;
    height: 100vh;
}

#resourcesList {
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
    justify-content: space-evenly;
    width: 75vw;
}

.resources h1 {
    color: #007b60;
    margin: 1em 0 0.5em 0;
    text-align: center;
}

.resources h2 {
    margin: 2em 0 0.2em 0;
}

.resources img {
    float: right;
    margin: 1.5em;
    mix-blend-mode: multiply;
    width: 30vw;
}

#beaverCoverArt {
    float: left;
    margin: 1em 1em 3em 0;
    width: 250px; 
    height: 250px;
}

#exerciseList {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
}


@media only screen and (max-width: 600px) {
    .resources {
        display: flex;
        flex-direction: column;
        margin-bottom: 60em;
    }

    .resources img {
        float: unset;
    }

  }
