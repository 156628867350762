.shop {
    padding-bottom: 7em;
    height: 70vh;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

h1 {
    color: #007b60;
    margin: 1em 0 0.5em 0;
    text-align: center;
}

#beaverCoverArtShop {
    font-size: 0;
    width: 250px; 
    height: 250px;
    border: solid;
    border-color: #d3d3d3;
    padding: 3px;
}

#bookLink {
    margin: 1em;
}

@media only screen and (max-width: 600px) {

    .shop {
        height: 180vh;
    }
  }
