.landing {
    height: 85vh;
    display: flex;
    flex-direction: row;
}

.landingMain {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: auto;
    /* height: 85vh; */
}

.landing h1 {
    color: #007b60;
    text-align: center;
    padding: 0.5em 0 0 0;
    font-size: 300%;
}

.browseBooks {
    background-color: #f2994a;
    height: 85vh;
    width: 20vw;
    text-align: left;
    padding: 1em;
    float: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.browseBooks h3 {
    background-color: #f2994a;
    color: whitesmoke;
}

.browseBooks h2 {
    background-color: #f2994a;
    color: whitesmoke;
}

.browseBooks h4 {
    background-color: #f2994a;
    color: whitesmoke;
    text-decoration: none;
}

.browseBooks h4:hover {
    color: darkblue;
}

.testimonials {
    background-color: #f2994a;
    padding-bottom: 100px;
}

.landingIcons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 75vw;
}

.landingIcon {
    margin: 3em;
    text-decoration: none;
    color: black;
}

.landingIcon  img{
    height: 9vw;
}

#backgroundImage {
    display: none;
}

@media only screen and (min-width: 1040px) {
    .landingMain {
        height: 85vh;
    }
}

@media only screen and (max-width: 1040px) {

    .landing {
        height: auto;
    }

    .browseBooks {
        display: none;
    }

    .landingIcons {
        margin: auto;
        display: block;
        justify-content: center;
        align-items: center;
    }
    
    .landingIcon {  
        margin: auto;
        display: flex;
        text-align: left;
        align-items: center;
    }
    
    .landingIcon  img{
        width: 100px;
        height: 100px;
        margin: 1em 1em 0 0;
    }

    #backgroundImage {
        display: block;
        width: 100vw;
        margin-top: 2em;
        margin-bottom: -2em;
    }

    #strapLine {
        margin: 0 1em 1em 1em;
    }
}