@import url(https://fonts.googleapis.com/css2?family=McLaren&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  background-color: whitesmoke;
}

.App {
  text-align: center;
  font-family: 'McLaren', cursive;
}

#logo {
  padding: 20px;
}

#comingSoon {
  color: #007b60;
}

.icon {
  margin: 10px;
  color: #007b60;
}
.social {
    background-color: #007b60;
    text-align: right;
}

.social a {
    background-color: #007b60;
    font-size: 120%;
    color: whitesmoke;
    text-align: right;
}

.icon {
    color: whitesmoke;
    background-color: #007b60;
    margin-right: 1em;
}

.icon:hover {
    color: #d3d3d3;
    background-color: #007b60;
}

.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1em;
    border-bottom: solid;
    border-bottom-width: 0.15em;
    border-bottom-color: #d3d3d3;
}

.navbar #menuLogo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    -webkit-text-decoration-color: black;
            text-decoration-color: black;
    text-decoration: none;
    color: #007b60;
    font-family: 'McLaren', cursive;
    text-align: left;
}

.navbar #menuLogo:hover {
    color: #ed364c;
}

.navbar #menuLogo h1 {
    padding-left: 0.5em;
}

.navbar .menuItems {
    display: flex;
    align-items: flex-end;
}

.navbar a {
    border: none;
    background-color: transparent;
    outline: none;
    color: #007b60;
    text-decoration: none;
    font-family: sans-serif;
    font-size: 85%;
    font-weight: normal;
    padding-right: 2em;
}

.navbar a:hover {
    color: #ed364c;
    text-decoration: none;
    font-family: sans-serif;
    font-weight: normal;
    cursor: pointer;
}

@media only screen and (max-width: 600px) {
    .navbar #menuLogo {
        display: none;
    }

    .navbar .menuItems {
        font-size: 45%;
    }

  }

  @media only screen and (min-width: 600px) and (max-width: 980px) {
    .navbar #menuLogo h1 {
        display: none;
    }

    .navbar #menuLogo img {
        width: 5em;
    }

    .navbar .menuItems {
        font-size: 75%;
    }

  }
.landing {
    height: 85vh;
    display: flex;
    flex-direction: row;
}

.landingMain {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: auto;
    /* height: 85vh; */
}

.landing h1 {
    color: #007b60;
    text-align: center;
    padding: 0.5em 0 0 0;
    font-size: 300%;
}

.browseBooks {
    background-color: #f2994a;
    height: 85vh;
    width: 20vw;
    text-align: left;
    padding: 1em;
    float: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.browseBooks h3 {
    background-color: #f2994a;
    color: whitesmoke;
}

.browseBooks h2 {
    background-color: #f2994a;
    color: whitesmoke;
}

.browseBooks h4 {
    background-color: #f2994a;
    color: whitesmoke;
    text-decoration: none;
}

.browseBooks h4:hover {
    color: darkblue;
}

.testimonials {
    background-color: #f2994a;
    padding-bottom: 100px;
}

.landingIcons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 75vw;
}

.landingIcon {
    margin: 3em;
    text-decoration: none;
    color: black;
}

.landingIcon  img{
    height: 9vw;
}

#backgroundImage {
    display: none;
}

@media only screen and (min-width: 1040px) {
    .landingMain {
        height: 85vh;
    }
}

@media only screen and (max-width: 1040px) {

    .landing {
        height: auto;
    }

    .browseBooks {
        display: none;
    }

    .landingIcons {
        margin: auto;
        display: block;
        justify-content: center;
        align-items: center;
    }
    
    .landingIcon {  
        margin: auto;
        display: flex;
        text-align: left;
        align-items: center;
    }
    
    .landingIcon  img{
        width: 100px;
        height: 100px;
        margin: 1em 1em 0 0;
    }

    #backgroundImage {
        display: block;
        width: 100vw;
        margin-top: 2em;
        margin-bottom: -2em;
    }

    #strapLine {
        margin: 0 1em 1em 1em;
    }
}
/* Slideshow container */

.slideshow-container {
    width: 20vw;
    height: calc(100% - 100px);
    align-items: center;
    position: relative;
    background: #f2994a;
    border: solid 1px black;
  }
  
  /* Slides */
  .mySlides {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #f2994a;
    padding: 0 6rem;
    height: 45vh;
  }
  
  /* Next & previous buttons */
  .prev, .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    margin-top: -6px;
    padding: 3.2px;
    color: #888;
    font-weight: bold;
    background: #f2994a;
    font-size: 1.5vw;
    border-radius: 0 0.5px 0.5px 0;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  
  /* Position the "next button" to the right */
  .next {
    position: absolute;
    right: 0;
    border-radius: 0.5px 0 0 0.5px;
  }
  
  /* On hover, add a black background color with a little bit see-through */
  .prev:hover, .next:hover {
    color: white;
  }
  
  /* The dot/bullet/indicator container */
  .dot-container {
    text-align: center;
    padding: 4px;
    background: #f2994a;
  }
  
  /* The dots/bullets/indicators */
  .dot {
    cursor: pointer;
    height: 6px;
    width: 6px;
    margin: 0 1.6px;
    background-color: #333;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
  }
  
  /* Add a background color to the active dot/circle */
  .active {
    background-color: white;
    height: 8px;
    width: 8px;
  }
  .dot:hover {
    background-color: #ccc;
  }
  
  
  /* Add an italic font style to all quotes */
  q {
    font-size: 1vw;
    font-style: italic;
    text-align: left;
    background: #f2994a;
  }
  
  /* Add a blue color to the author */
  .author {
    font-size: 1vw;
    color: #007b60;
    text-align: right;
    background: #f2994a;
  }
  
  @media only screen and (min-width: 1000px) {
    .mySlides {
      padding: 0 2rem;
    }
  }
.about {
    margin: auto;
    width: 90vw;
    text-align: justify;
    padding-bottom: 7em;
}

.about h1 {
    color: #007b60;
    margin: 1em 0 0.5em 0;
    text-align: center;
}

.about h2 {
    margin: 2em 0 0.2em 0;
}

.about h3 {
    margin: 2em 0 0.2em 0;
}

.about img {
    mix-blend-mode: multiply;
    margin-right: 1em;
}
.mentalToughness {
    margin: auto;
    width: 90vw;
    text-align: justify;
    padding-bottom: 7em;
}

.mentalToughness h1 {
    color: #007b60;
    margin: 1em 0 0.5em 0;
    text-align: center;
}

.mentalToughness h2 {
    margin: 2em 0 1em 0;
}

table {
    margin: 1em 0;
    width: 90vw;
    border: 1px solid black;
    border-collapse: collapse;
    color: whitesmoke;
}

th {
    border: 1px solid black;
    border-collapse: collapse;
    width: 10vw;
    padding: 0.5em;
    text-align: center;
}

td {
    border: 1px solid black;
    border-collapse: collapse;
    width: 70vw;
    padding: 0.5em;
}

#MTvideo {
    margin-top: 1em;
    border: solid;
    border-width: 1px;
    border-color: black;
}

#birdMouseQuestion {
    width: 30vw;
    float: right;
    margin: 1.5em;
    mix-blend-mode: multiply;
}

@media only screen and (max-width: 900px) {
    #MTvideo {
        width: 90vw;
    }
}
.books {
    margin: auto;
    width: 90vw;
    text-align: justify;
    padding-bottom: 7em;
}

.books h1 {
    color: #007b60;
    margin: 1em 0 0.5em 0;
    text-align: center;
}

.books h2 {
    margin: 2em 0 0.2em 0;
}

#coverArtBooks {
    margin: 0 1em 5em 0;
    width: 250px; 
    height: 250px;
    border: solid;
    border-color: #d3d3d3;
    padding: 0.1em;
}

#beaverBooks {
    width: 90vw;
    display: flex;
}

#bookBio {
    display: flex;
    flex-direction: column;
}

@media only screen and (max-width: 600px) {
    #beaverBooks {
        display: block;
    }
    
    #coverArtBooks {
        margin: 2em 0 1em 0;
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: auto;
    }

}
.resources {
    margin: auto;
    width: 90vw;
    text-align: justify;
    padding-bottom: 60em;
    height: 100vh;
}

#resourcesList {
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
    justify-content: space-evenly;
    width: 75vw;
}

.resources h1 {
    color: #007b60;
    margin: 1em 0 0.5em 0;
    text-align: center;
}

.resources h2 {
    margin: 2em 0 0.2em 0;
}

.resources img {
    float: right;
    margin: 1.5em;
    mix-blend-mode: multiply;
    width: 30vw;
}

#beaverCoverArt {
    float: left;
    margin: 1em 1em 3em 0;
    width: 250px; 
    height: 250px;
}

#exerciseList {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
}


@media only screen and (max-width: 600px) {
    .resources {
        display: flex;
        flex-direction: column;
        margin-bottom: 60em;
    }

    .resources img {
        float: unset;
    }

  }


.submitButton {
    text-decoration: none;
    background-color: #007b60;
    padding: 0.5em;
    border-radius: 1em;
    color: whitesmoke;
    font-weight: bold;
    margin-top: 1em;
}

.inputField__label {
    display: flex;
    flex-direction: column;
    margin-top: 0.8em;
    font-family: sans-serif;
}

.inputField__label input {
    width: 60%;
    border: solid;
    border-width: 1px;
    border-color: #5c5c5c;
}
.blog {
    margin: auto;
    width: 90vw;
    height: 60vh;
    text-align: center;
    padding-bottom: 7em;
}

.blog h1 {
    color: #007b60;
    margin: 1em 0 0.2em 0;
    text-align: center;
}

.blog h2 {
    margin: 2em 0 0.2em 0;
}

#facebookPosts {
    color: #007b60;
    margin: 1em 0 0.2em 0;
    width: 340px;
    height: 500px;
}
.shop {
    padding-bottom: 7em;
    height: 70vh;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

h1 {
    color: #007b60;
    margin: 1em 0 0.5em 0;
    text-align: center;
}

#beaverCoverArtShop {
    font-size: 0;
    width: 250px; 
    height: 250px;
    border: solid;
    border-color: #d3d3d3;
    padding: 3px;
}

#bookLink {
    margin: 1em;
}

@media only screen and (max-width: 600px) {

    .shop {
        height: 180vh;
    }
  }

.footer {
    margin-top: 2em;
    background-color: #c4c4c4;
    padding: 2em 1.5em;
    display: flex;
    justify-content: space-between;
    z-index: 10;
}

.footer .footerHeader {
    text-align: left;
    color: whitesmoke;
    width: 40vw;
}

.footer .footerHeader p {
    background-color: #c4c4c4;
}

.footer .footerHeader h2 {
    background-color: #c4c4c4;
}

.footer .footerMenu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 60vw;
    background-color: #c4c4c4;
}

.footer a {
    text-decoration: none;
    background-color: #c4c4c4;
    color: whitesmoke;
    font-size: 80%;
}

.footer a:hover {
    color: #007b60;
}

#divisionOf {
    font-size: 50%;
    background-color: #c4c4c4;
}

@media only screen and (min-width: 750px) and (max-width: 980px) {

    .footer .footerHeader {
        width: 30vw;
        font-size: 75%;
    }

    .footer .footerMenu {
        width: 70vw;
    }

    .footer a {
        font-size: 75%;
    }
}

@media only screen and (max-width: 750px) {

    .footer {
        display: flex;
        flex-direction: column-reverse;
    }

    .footer .footerHeader {
        width: auto;
        margin-top: 1.5em;
    }

    .footer .footerMenu {
        width: auto;
        display: flex;
        flex-direction: column;
        align-items: unset;
        text-align: left;
    }

    .footer .footerMenu a {
        margin-top: 0.2em;
    }
}

.optimiseLearning {
    margin: auto;
    width: 90vw;
    text-align: justify;
    padding-bottom: 7em;
}

.optimiseLearning h1 {
    color: #007b60;
    margin: 1em 0 0.5em 0;
    text-align: center;
}

.optimiseLearning h2 {
    margin: 2em 0 0.2em 0;
}

.optimiseLearning img {
    float: right;
    margin: 1.5em;
    mix-blend-mode: multiply;
    width: 30vw;
}
