.optimiseLearning {
    margin: auto;
    width: 90vw;
    text-align: justify;
    padding-bottom: 7em;
}

.optimiseLearning h1 {
    color: #007b60;
    margin: 1em 0 0.5em 0;
    text-align: center;
}

.optimiseLearning h2 {
    margin: 2em 0 0.2em 0;
}

.optimiseLearning img {
    float: right;
    margin: 1.5em;
    mix-blend-mode: multiply;
    width: 30vw;
}