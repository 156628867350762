.about {
    margin: auto;
    width: 90vw;
    text-align: justify;
    padding-bottom: 7em;
}

.about h1 {
    color: #007b60;
    margin: 1em 0 0.5em 0;
    text-align: center;
}

.about h2 {
    margin: 2em 0 0.2em 0;
}

.about h3 {
    margin: 2em 0 0.2em 0;
}

.about img {
    mix-blend-mode: multiply;
    margin-right: 1em;
}