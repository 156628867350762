.submitButton {
    text-decoration: none;
    background-color: #007b60;
    padding: 0.5em;
    border-radius: 1em;
    color: whitesmoke;
    font-weight: bold;
    margin-top: 1em;
}

.inputField__label {
    display: flex;
    flex-direction: column;
    margin-top: 0.8em;
    font-family: sans-serif;
}

.inputField__label input {
    width: 60%;
    border: solid;
    border-width: 1px;
    border-color: #5c5c5c;
}