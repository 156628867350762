@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@900&display=swap');

.social {
    background-color: #007b60;
    text-align: right;
}

.social a {
    background-color: #007b60;
    font-size: 120%;
    color: whitesmoke;
    text-align: right;
}

.icon {
    color: whitesmoke;
    background-color: #007b60;
    margin-right: 1em;
}

.icon:hover {
    color: #d3d3d3;
    background-color: #007b60;
}

.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1em;
    border-bottom: solid;
    border-bottom-width: 0.15em;
    border-bottom-color: #d3d3d3;
}

.navbar #menuLogo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration-color: black;
    text-decoration: none;
    color: #007b60;
    font-family: 'McLaren', cursive;
    text-align: left;
}

.navbar #menuLogo:hover {
    color: #ed364c;
}

.navbar #menuLogo h1 {
    padding-left: 0.5em;
}

.navbar .menuItems {
    display: flex;
    align-items: flex-end;
}

.navbar a {
    border: none;
    background-color: transparent;
    outline: none;
    color: #007b60;
    text-decoration: none;
    font-family: sans-serif;
    font-size: 85%;
    font-weight: normal;
    padding-right: 2em;
}

.navbar a:hover {
    color: #ed364c;
    text-decoration: none;
    font-family: sans-serif;
    font-weight: normal;
    cursor: pointer;
}

@media only screen and (max-width: 600px) {
    .navbar #menuLogo {
        display: none;
    }

    .navbar .menuItems {
        font-size: 45%;
    }

  }

  @media only screen and (min-width: 600px) and (max-width: 980px) {
    .navbar #menuLogo h1 {
        display: none;
    }

    .navbar #menuLogo img {
        width: 5em;
    }

    .navbar .menuItems {
        font-size: 75%;
    }

  }