@import url('https://fonts.googleapis.com/css2?family=McLaren&display=swap');

* {
  margin: 0;
  padding: 0;
  background-color: whitesmoke;
}

.App {
  text-align: center;
  font-family: 'McLaren', cursive;
}

#logo {
  padding: 20px;
}

#comingSoon {
  color: #007b60;
}

.icon {
  margin: 10px;
  color: #007b60;
}