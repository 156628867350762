.books {
    margin: auto;
    width: 90vw;
    text-align: justify;
    padding-bottom: 7em;
}

.books h1 {
    color: #007b60;
    margin: 1em 0 0.5em 0;
    text-align: center;
}

.books h2 {
    margin: 2em 0 0.2em 0;
}

#coverArtBooks {
    margin: 0 1em 5em 0;
    width: 250px; 
    height: 250px;
    border: solid;
    border-color: #d3d3d3;
    padding: 0.1em;
}

#beaverBooks {
    width: 90vw;
    display: flex;
}

#bookBio {
    display: flex;
    flex-direction: column;
}

@media only screen and (max-width: 600px) {
    #beaverBooks {
        display: block;
    }
    
    #coverArtBooks {
        margin: 2em 0 1em 0;
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: auto;
    }

}