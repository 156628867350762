/* Slideshow container */

.slideshow-container {
    width: 20vw;
    height: calc(100% - 100px);
    align-items: center;
    position: relative;
    background: #f2994a;
    border: solid 1px black;
  }
  
  /* Slides */
  .mySlides {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #f2994a;
    padding: 0 6rem;
    height: 45vh;
  }
  
  /* Next & previous buttons */
  .prev, .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    margin-top: -6px;
    padding: 3.2px;
    color: #888;
    font-weight: bold;
    background: #f2994a;
    font-size: 1.5vw;
    border-radius: 0 0.5px 0.5px 0;
    user-select: none;
  }
  
  /* Position the "next button" to the right */
  .next {
    position: absolute;
    right: 0;
    border-radius: 0.5px 0 0 0.5px;
  }
  
  /* On hover, add a black background color with a little bit see-through */
  .prev:hover, .next:hover {
    color: white;
  }
  
  /* The dot/bullet/indicator container */
  .dot-container {
    text-align: center;
    padding: 4px;
    background: #f2994a;
  }
  
  /* The dots/bullets/indicators */
  .dot {
    cursor: pointer;
    height: 6px;
    width: 6px;
    margin: 0 1.6px;
    background-color: #333;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
  }
  
  /* Add a background color to the active dot/circle */
  .active {
    background-color: white;
    height: 8px;
    width: 8px;
  }
  .dot:hover {
    background-color: #ccc;
  }
  
  
  /* Add an italic font style to all quotes */
  q {
    font-size: 1vw;
    font-style: italic;
    text-align: left;
    background: #f2994a;
  }
  
  /* Add a blue color to the author */
  .author {
    font-size: 1vw;
    color: #007b60;
    text-align: right;
    background: #f2994a;
  }
  
  @media only screen and (min-width: 1000px) {
    .mySlides {
      padding: 0 2rem;
    }
  }