.mentalToughness {
    margin: auto;
    width: 90vw;
    text-align: justify;
    padding-bottom: 7em;
}

.mentalToughness h1 {
    color: #007b60;
    margin: 1em 0 0.5em 0;
    text-align: center;
}

.mentalToughness h2 {
    margin: 2em 0 1em 0;
}

table {
    margin: 1em 0;
    width: 90vw;
    border: 1px solid black;
    border-collapse: collapse;
    color: whitesmoke;
}

th {
    border: 1px solid black;
    border-collapse: collapse;
    width: 10vw;
    padding: 0.5em;
    text-align: center;
}

td {
    border: 1px solid black;
    border-collapse: collapse;
    width: 70vw;
    padding: 0.5em;
}

#MTvideo {
    margin-top: 1em;
    border: solid;
    border-width: 1px;
    border-color: black;
}

#birdMouseQuestion {
    width: 30vw;
    float: right;
    margin: 1.5em;
    mix-blend-mode: multiply;
}

@media only screen and (max-width: 900px) {
    #MTvideo {
        width: 90vw;
    }
}