.footer {
    margin-top: 2em;
    background-color: #c4c4c4;
    padding: 2em 1.5em;
    display: flex;
    justify-content: space-between;
    z-index: 10;
}

.footer .footerHeader {
    text-align: left;
    color: whitesmoke;
    width: 40vw;
}

.footer .footerHeader p {
    background-color: #c4c4c4;
}

.footer .footerHeader h2 {
    background-color: #c4c4c4;
}

.footer .footerMenu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 60vw;
    background-color: #c4c4c4;
}

.footer a {
    text-decoration: none;
    background-color: #c4c4c4;
    color: whitesmoke;
    font-size: 80%;
}

.footer a:hover {
    color: #007b60;
}

#divisionOf {
    font-size: 50%;
    background-color: #c4c4c4;
}

@media only screen and (min-width: 750px) and (max-width: 980px) {

    .footer .footerHeader {
        width: 30vw;
        font-size: 75%;
    }

    .footer .footerMenu {
        width: 70vw;
    }

    .footer a {
        font-size: 75%;
    }
}

@media only screen and (max-width: 750px) {

    .footer {
        display: flex;
        flex-direction: column-reverse;
    }

    .footer .footerHeader {
        width: auto;
        margin-top: 1.5em;
    }

    .footer .footerMenu {
        width: auto;
        display: flex;
        flex-direction: column;
        align-items: unset;
        text-align: left;
    }

    .footer .footerMenu a {
        margin-top: 0.2em;
    }
}